import { mapGetters, mapState } from 'vuex'
import { swagger_mixin } from '@/mixins/swagger'

export default {
    data() {
        return {}
    },
    mixins: [swagger_mixin],
    computed: {
        ...mapGetters(['selected_token_json', 'access_rights']),
        ...mapState({
            user_extended_data: (state) => state.user_extended_data,
            schemas_in_memory: (state) => state.swagger.schemas_in_memory,
        }),
    },
    methods: {
        has_post_access(resource) {
            return this.has_access_to_resource_method(resource, 'POST') && this.has_valid_filter(resource, 'POST')
        },
        has_get_resource_access(resource) {
            return this.has_access_to_resource_method(resource, 'GET')
        },
        has_put_item_access(resource) {
            return this.has_access_to_item_method(resource, 'PUT') && this.allow_put(resource)
        },
        has_patch_access(resource) {
            return this.has_access_to_item_method(resource, 'PATCH') && this.has_valid_filter(resource, 'PATCH')
        },
        has_delete_access(resource) {
            return this.has_access_to_item_method(resource, 'DELETE')
        },
        resource_access(endpoint) {
            return this.access_rights ? this.access_rights[endpoint] : undefined
        },
        resource_access_swagger_schema(resource) {
            // Assumes access--groups swagger has been loaded (by a watcher in App.vue)
            const schema = this.schemas_in_memory['access--groups'] // Get value directly from store to avoid Promise
            return schema?.properties?.access_rights?.properties?.[resource]?.properties
        },
        has_access_to_resource_method(resource, method) {
            return this.resource_method_exists(resource, method) && this.has_method_access(resource, method)
        },
        has_access_to_item_method(resource, method) {
            return this.item_method_exists(resource, method) && this.has_method_access(resource, method)
        },
        resource_method_exists(resource, method) {
            let swagger_paths = this.get_swagger_resource_paths(resource)
            return method.toLowerCase() in swagger_paths['resource_methods']
        },
        item_method_exists(resource, method) {
            let swagger_paths = this.get_swagger_resource_paths(resource)
            return method.toLowerCase() in swagger_paths['item_methods']
        },
        has_method_access(resource, method) {
            if (this.selected_token_json?.system_user) return true

            let resource_access = this.resource_access(resource)
            let methods = resource_access ? resource_access['methods'] || [] : []
            return methods.indexOf(method.toUpperCase()) > -1
        },
        write_access_to_attribute(resource, property, access_right = null) {
            if (access_right === null) {
                access_right = this.resource_access(resource)
            }

            property = property.split('.').at(0)

            return (
                access_right &&
                (access_right['full_attribute_access'] || access_right['attribute_access']?.[property] === 'write')
            )
        },
        access_to_attribute(resource, property, access_right = null) {
            if (access_right === null) {
                access_right = this.resource_access(resource)
            }

            property = property.split('.').at(0)

            return (
                access_right &&
                (access_right['full_attribute_access'] ||
                    access_right['attribute_access']?.[property] === 'read' ||
                    access_right['attribute_access']?.[property] === 'write' ||
                    property.startsWith('_'))
            )
        },
        allow_put(resource, access_right = null) {
            if (!access_right) {
                access_right = this.resource_access(resource)
            }

            if (this.selected_token_json.system_user) {
                let swagger_paths = this.get_swagger_resource_paths(resource)
                return 'put' in swagger_paths['item_methods']
            }

            return access_right?.methods?.includes('PUT') && access_right?.full_attribute_access
        },
        resource_schema_has_filter(resource) {
            const schema = this.resource_access_swagger_schema(resource)
            return schema?.['full_filter_access']
        },
        has_valid_filter(resource, method) {
            if (!this.resource_schema_has_filter(resource)) {
                return true
            }

            const access_rights = this.resource_access(resource)
            const filter = access_rights?.['filter']
            const user_filter = access_rights?.['user_filter']
            const full_filter_access = access_rights?.['full_filter_access']

            if (full_filter_access) {
                return true
            }

            if (method.toUpperCase() === 'PUT' && !this.allow_put(resource, access_rights)) {
                return false
            }

            if (filter) {
                for (const filter_value of Object.values(filter)) {
                    if (filter_value?.length) {
                        return true
                    }
                }
            }

            if (user_filter) {
                for (const filter_value of Object.values(user_filter)) {
                    if (filter_value) {
                        return true
                    }
                }
            }

            return false
        },
        resource_filter_keys(resource) {
            const schema = this.resource_access_swagger_schema(resource)
            return Object.keys(schema?.filter?.properties || {})
        },
        merge_valid_access_rights_for_create_or_edit(resource, method) {
            const access_groups = this.user_extended_data?.['access_groups']
            if (!access_groups) {
                return undefined
            }

            let merged_access_rights = undefined

            for (const access_group of access_groups) {
                const access_rights = access_group['access_rights']?.[resource]
                const allowed_methods = access_rights?.['methods']
                if (!access_rights || !allowed_methods) {
                    continue
                }

                const has_method_access = allowed_methods.includes(method.toUpperCase())
                const is_valid = has_method_access && this.has_valid_filter(resource, method)
                if (!is_valid) {
                    continue
                }

                if (!merged_access_rights) {
                    merged_access_rights = {}
                }

                if (access_rights['full_filter_access']) {
                    merged_access_rights['full_filter_access'] = true
                    return merged_access_rights
                }

                if (access_rights['filter']) {
                    if (!merged_access_rights['filter']) {
                        merged_access_rights['filter'] = {}
                    }

                    for (const [key, filter_value] of Object.entries(access_rights['filter'])) {
                        const merged_filter_value = merged_access_rights['filter']?.[key] || []
                        merged_access_rights['filter'][key] = [...filter_value, ...merged_filter_value]
                    }
                }
            }

            return merged_access_rights
        },
        filter_for_reference_input(resource, method, key) {
            let filter = {}

            if (!this.resource_schema_has_filter(resource) || this.selected_token_json?.system_user) {
                return undefined
            }

            const filter_keys = this.resource_filter_keys(resource)
            if (!filter_keys.includes(key)) {
                return undefined
            }

            const access_rights = this.merge_valid_access_rights_for_create_or_edit(resource, method)
            if (!access_rights || access_rights['full_filter_access']) {
                return undefined
            }

            const filter_value = access_rights?.['filter']?.[key]
            if (filter_value && filter_value.length > 0) {
                // _id since lookup will be used in GET on the same resource that the filter key refers to
                filter['_id'] = { $in: filter_value.map((x) => x['id']) }
            }

            return Object.keys(filter).length > 0 ? filter : undefined
        },
    },
}
