<template>
    <div />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api_mixin from '@/mixins/common/api_mixin'
import date_mixin from '@/mixins/common/date_mixin'

export default {
    name: 'TheWootric',
    mixins: [api_mixin, date_mixin],
    computed: {
        ...mapGetters({
            user_extended_data_loaded: 'user_extended_data_loaded',
            selected_token_json: 'selected_token_json',
        }),
        ...mapState({
            user_extended_data: 'user_extended_data',
        }),
    },
    created() {
        let { email_data, created, solicitor } = this.user_extended_data
        if (email_data && email_data.email && created && !solicitor) {
            let d = new Date(this.user_extended_data.created)
            let user_created_date = d.getTime() / 1000

            // Test flags
            // window.wootric_survey_immediately = true; //Overwrites sampling settings and forces the server to return true to all survey requests.
            // window.wootric_no_surveyed_cookie = true; //Disables the cookie writing after a survey is taken effectively disabling any client side mechanisms to prevent multiple surveys from being rendered.
            window.wootricSettings = {
                email: email_data.email,
                created_at: user_created_date,
                product_name: 'Amili',
                properties: {
                    user_application: 'Amili',
                },
                account_token: 'NPS-EU-91692e58',
            }
            window.wootric('run')
        }
    },
}
</script>

<style scoped></style>
