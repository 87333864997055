<template>
    <v-app-bar
        class="pa-0"
        color="primary"
        app
        dense
    >
        <template #default>
            <div
                class="d-flex justify-center align-center"
                style="width: 100%"
            >
                <v-app-bar-nav-icon
                    v-if="$vuetify.breakpoint.smAndDown"
                    @click="$emit('open_left_navigation_drawer')"
                />
                <img
                    @click="go_to_home"
                    @click.middle="open_home_in_new_window"
                    id="help_home"
                    alt="logo"
                    class="mx-4 app-object--clickable"
                    height="24"
                    src="@/assets/logo_white.png"
                />
                <v-tabs
                    v-if="$vuetify.breakpoint.mdAndUp"
                    v-model="current_tab"
                    class="ml-2"
                    color="primary"
                    dark
                    optional
                >
                    <v-tabs-slider color="brand_primary" />
                    <v-tab
                        v-for="(menu, index) in filtered_navigation_menu_without_other"
                        :key="index"
                        @click="handle_tab_click(menu, index)"
                    >
                        <v-badge
                            :content="badges[index]"
                            :value="Boolean(menu.badge && badges[index])"
                            color="brand_primary"
                        >
                            {{ translate(menu.locale_name) }}
                        </v-badge>
                        <v-icon
                            v-if="menu.sub_menu"
                            :style="{
                                transform: current_tab === index ? 'rotate(180deg)' : '',
                            }"
                            class="ml-2"
                            small
                        >
                            mdi-chevron-down
                        </v-icon>
                    </v-tab>
                    <TheHeaderOtherMenu
                        :other_navigation_menu="other_navigation_menu"
                        @reset_current_tab="current_tab = undefined"
                    />
                </v-tabs>

                <AppEnvironmentChip class="mr-2" />

                <VismaNetTogglePartnerAccount v-if="user_extended_data.oauth_type === 'visma_net'" />
                <TokenListToggle
                    v-if="multiple_tokens"
                    class="mr-2"
                />

                <v-divider vertical />
                <TheHeaderIconBar
                    @open_right_navigation_drawer="$emit('open_right_navigation_drawer')"
                    @right_navigation_component="$emit('right_navigation_component', $event)"
                />
            </div>
        </template>
        <template
            v-if="show_extension"
            #extension
        >
            <v-toolbar
                color="#333333"
                dense
            >
                <v-tabs-items
                    ref="menu"
                    v-model="current_tab"
                    style="background-color: inherit; width: 100%"
                >
                    <v-tab-item
                        v-for="(menu, index) in filtered_navigation_menu_without_other"
                        :key="index"
                    >
                        <v-tabs
                            v-model="current_sub_tab"
                            optional
                        >
                            <v-tabs-slider color="brand_primary" />
                            <template v-for="(sub_menu, s_index) in menu.sub_menu">
                                <v-menu
                                    v-if="sub_menu.list_menu"
                                    :key="`${s_index}_menu`"
                                    v-model="sub_menus[s_index]"
                                    :open-on-click="current_sub_tab === undefined || current_sub_tab !== s_index"
                                    :open-on-hover="current_sub_tab === s_index"
                                    close-delay="100"
                                    nudge-bottom="4"
                                    offset-y
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-tab
                                            v-bind="attrs"
                                            :key="s_index"
                                            v-on="on"
                                            color="transparent"
                                            label
                                        >
                                            {{ translate(sub_menu.locale_name) }}
                                            <v-icon
                                                :style="{
                                                    transform: current_sub_tab === s_index && sub_menus[s_index] ? 'rotate(180deg)' : '',
                                                }"
                                                class="ml-2"
                                                small
                                            >
                                                mdi-chevron-down
                                            </v-icon>
                                        </v-tab>
                                    </template>
                                    <v-list
                                        color="#333333"
                                        dark
                                        dense
                                    >
                                        <v-list-item
                                            v-for="(list_menu, l_index) in sub_menu.list_menu"
                                            :key="l_index"
                                            :to="list_menu"
                                        >
                                            <v-list-item-title style="color: #fff">
                                                {{ translate(list_menu.locale_name) }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-tab
                                    v-else
                                    :key="`${s_index}_tab`"
                                    :to="sub_menu"
                                >
                                    {{ translate(sub_menu.locale_name) }}
                                </v-tab>
                            </template>
                        </v-tabs>
                    </v-tab-item>
                </v-tabs-items>
            </v-toolbar>
        </template>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import TheHeaderIconBar from '@/components/the_header/icon_bar'
import TheHeaderOtherMenu from '@/components/the_header/other'
import AppEnvironmentChip from '@/components/app_environment_chip'
import VismaNetTogglePartnerAccount from '@/components/the_header/other/TheHeaderVismaNetTogglePartnerAccount.vue'
import TokenListToggle from '@/components/token_list_toggle/index.vue'

export default {
    name: 'TheHeader',
    components: {
        TokenListToggle,
        VismaNetTogglePartnerAccount,
        AppEnvironmentChip,
        TheHeaderOtherMenu,
        TheHeaderIconBar,
    },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            current_tab: undefined,
            current_sub_tab: undefined,
            sub_menus: [],
            badges: [],
        }
    },
    computed: {
        filtered_navigation_menu_without_other() {
            return this.filtered_navigation_menu.filter((v) => v.locale_name !== 'common.other')
        },
        other_navigation_menu() {
            return this.filtered_navigation_menu.find((v) => v.locale_name === 'common.other')
        },
        show_extension() {
            if (this.current_tab === undefined) return false
            if (this.$vuetify.breakpoint.smAndDown) return false

            return this.filtered_navigation_menu_without_other[this.current_tab]?.sub_menu
        },
        ...mapGetters(['multiple_tokens']),
        ...mapState([]),
    },
    watch: {
        current_sub_tab(value) {
            if (value === undefined) {
                for (let i = 0; i < this.sub_menus.length; i++) {
                    this.sub_menus[i] = false
                }
            }
        },
        $route() {
            const { parent } = this.$route.params
            const finance_parents = ['settlement', 'payouts', 'invoicing', 'crediting', 'commission', 'banking', 'finance_technical']
            const index = this.filtered_navigation_menu_without_other.findIndex((v) => v.locale_name.includes(parent))
            if (index === -1) {
                if (finance_parents.includes(parent)) {
                    this.current_tab = this.filtered_navigation_menu_without_other.findIndex((v) => v.locale_name.includes('finance'))
                } else if (this.$route.name === 'Tickets' || this.$route.name === 'Ticket') {
                    this.current_tab = this.filtered_navigation_menu_without_other.findIndex((v) => v.locale_name.includes('tickets'))
                } else if (this.$route.name === 'Decisions') {
                    this.current_tab = this.filtered_navigation_menu_without_other.findIndex((v) => v.locale_name.includes('decisions'))
                }
            } else {
                this.current_tab = index
            }
        },
        filtered_navigation_menu_without_other: {
            handler(data) {
                if (data.length) {
                    this.handle_badges(data)
                }
            },
            immediate: true,
        },
    },
    methods: {
        open_home_in_new_window() {
            window.open(window.location.origin, '_blank')
        },
        handle_tab_click(menu, index) {
            let route = null
            if (menu['sub_menu']) {
                if (this.current_tab === index) {
                    return
                } else {
                    this.current_sub_tab = undefined
                }

                if (!menu['sub_menu'][0]['list_menu']) {
                    route = menu['sub_menu'][0]
                }
            } else {
                route = menu
            }
            if (!route) return
            if (this.$router.resolve(route).href !== this.$router.resolve(this.$route).href) {
                this.$router.push(route)
            } else if (!menu['sub_menu']) {
                window.location.reload()
            }
        },
        go_to_home() {
            this.current_tab = undefined
            this.go_to('Home')
        },
        async handle_badges(data) {
            const result = []
            for await (const x of data) {
                if (!x.badge) {
                    result.push(0)
                    continue
                }

                try {
                    const where = JSON.parse(x.badge['lookup'])
                    const resource = x.badge['resource']
                    const response = await this.api_get({
                        url: `/${resource}`,
                        params: {
                            where: where,
                            projection: {
                                _id: 1,
                            },
                            max_results: 1,
                        },
                    })
                    const total = this.deep_get(response, 'data._meta.total') || 0
                    result.push(total)
                } catch (e) {
                    result.push(0)
                }
            }
            this.badges = result
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style lang="sass" scoped>
::v-deep .v-toolbar__extension
    padding: 0

::v-deep .v-tabs-bar
    background-color: initial !important

::v-deep .v-tab
    color: #FFFFFF !important
    font-weight: 500

.v-chip
    color: #ffffff !important

::v-deep .v-badge__badge
    color: #0A0D0D !important

::v-deep .theme--light.v-icon.v-icon,
::v-deep .theme--light.v-icon.v-icon--disabled
    color: #ffffff !important

    &.v-icon--disabled
        opacity: 0.2
</style>
