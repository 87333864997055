import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import VueJwtDecode from 'vue-jwt-decode'
import { multi_tab_state_mixin } from '@/mixins/multi_tab_state/mixin'

export default {
    mixins: [multi_tab_state_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['selected_token_json', 'token_type']),
        ...mapState(['user_tokens']),
    },
    watch: {},
    methods: {
        async get_token_from_local_storage(attempts = 0) {
            const { token_list, selected_token } = this.get_local_storage('user_tokens') || {}

            if (token_list) {
                return token_list[selected_token || 0].token
            }

            if (attempts > 10) {
                return null
            }
            await this.sleep(250)
            return await this.get_token_from_local_storage(attempts + 1)
        },
        get_token_from_url() {
            let token_list = this.get_url_query_parameter('token_list')
            if (!token_list.length) {
                return false
            }
            window.history.replaceState('', '', '/')

            token_list = JSON.parse(token_list)
            return {
                token_list: token_list,
                selected_token: 0,
            }
        },

        select_token(new_selected_token) {
            const user_tokens = structuredClone(this.user_tokens)
            const { token_list, selected_token } = user_tokens

            if (new_selected_token >= token_list.length) {
                return
            }

            if (this.selected_token_json.signed_in_by_u_id) {
                token_list.splice(selected_token, 1)

                if (selected_token < new_selected_token) {
                    new_selected_token -= 1
                }
            }

            user_tokens.selected_token = new_selected_token
            this.update_multi_tab_data('user_tokens', user_tokens)
        },
        update_selected_token(token_data) {
            const user_tokens = structuredClone(this.user_tokens)
            const { selected_token } = user_tokens

            user_tokens.token_list[selected_token] = token_data

            this.update_multi_tab_data('user_tokens', user_tokens)
        },

        async token_exist_and_must_be_refreshed(safety_margin_seconds = 0) {
            const token = await this.get_token_from_local_storage()
            if (!token) {
                return false
            }

            const token_json = VueJwtDecode.decode(token)
            return Date.now() >= (token_json.exp - safety_margin_seconds) * 1000
        },
        token_expired(safety_margin_seconds = 0) {
            const token_exp = this.selected_token_json?.exp || 0
            return Date.now() >= (token_exp - safety_margin_seconds) * 1000
        },
        debounce_refresh_token: debounce(async function () {
            await this.refresh_token()
        }, 250),
        async refresh_token() {
            try {
                console.log(`<<<Refresh Token - ${new Date().getTime()} >>>`)
                let result = await this.api_post({
                    url: '/authenticates/refresh-token',
                })

                if (result.status === 202) {
                    console.warn('Token was recently refreshed')
                } else if (result.status === 200) {
                    this.update_selected_token(result.data)
                    await this.get_application_details()
                } else {
                    console.warn('Token expired: ', this.token)
                    await this.sign_out('token_expired')
                }
            } catch (err) {
                await this.sign_out('token_expired')
            } finally {
                this.set_state_property({
                    state_property: 'token_has_expired',
                    data: false,
                })
            }
        },
        ...mapActions(['set_state_property']),
    },
}
