import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },

    {
        path: '/visma/finance-invalid-payments',
        name: 'FinanceInvalidPayments',
        component: () => import(/*webpackChunkName "finance_summary"*/ '@/views/menu/finance/FinanceInvalidPayments'),
        props: (route) => ({
            tab: route.query.tab,
            id: route.query.id,
        }),
    },
    {
        path: '/item-search/:parent?/:resource',
        name: 'ItemSearch',
        component: () => import(/*webpackChunkName "item_search"*/ '@/views/ItemSearch'),
        props: (route) => ({
            text_search: route.query.text_search,
            filter: route.query.filter,
            parent: route.params.parent,
            resource: route.params.resource,
            page: route.query.page,
            page_max_results: route.query.page_max_results,
            sort: route.query.sort,
        }),
    },
    {
        path: '/item-display/:resource/:resource_id',
        name: 'ItemDisplay',
        component: () => import(/*webpackChunkName "item-display"*/ '@/views/ItemDisplay.vue'),
        props: (route) => ({
            resource: route.params.resource,
            resource_id: route.params.resource_id,
            tab: route.query.tab,
            display_mode: route.query.mode || 'view',
        }),
    },
    {
        path: '/item-display/:resource/:resource_id/foreign-key/:foreign_resource/:foreign_key',
        name: 'ItemDisplayForeignKey',
        component: () => import(/*webpackChunkName "item-display-foreign-key"*/ '@/views/ItemDisplayForeignKey.vue'),
        props: (route) => ({
            resource: route.params.resource,
            resource_id: route.params.resource_id,
            foreign_resource: route.params.foreign_resource,
            foreign_key: route.params.foreign_key,
            tab_on_return: route.params.tab_on_return,
        }),
    },
    {
        path: '/open',
        name: 'Open',
        component: () => import(/*webpackChunkName "open"*/ '@/views/Open'),
        props: (route) => ({
            resource: route.query.resource,
            id: route.query.id,
            route: route.query.route,
        }),
    },
    {
        path: '/tickets/:channel',
        name: 'Tickets',
        component: () => import(/*webpackChunkName "tickets"*/ '@/views/menu/tickets/Tickets'),
        props: (route) => ({
            channel: route.params.channel,
            parent: 'tickets',
        }),
    },
    {
        path: '/tickets/:channel/ticket',
        name: 'Ticket',
        component: () => import(/*webpackChunkName "ticket"*/ '@/views/menu/tickets/Ticket'),
        props: (route) => ({
            channel: route.params.channel,
            value: route.params.value,
            ticket_id: route.query.ticket_id,
        }),
    },
    {
        path: '/case-registration',
        name: 'CaseRegistration',
        component: () =>
            import(/*webpackChunkName "case-registration"*/ '@/views/menu/case_registration/CaseRegistration'),
        props: (route) => ({}),
    },
    {
        path: '/case-registration/summary/:case_registration_id',
        name: 'CaseRegistrationSummary',
        component: () =>
            import(
                /*webpackChunkName "case-registration-summary"*/ '@/views/menu/case_registration/CaseRegistrationSummary'
            ),
        props: (route) => ({
            case_registration_id: route.params.case_registration_id,
        }),
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/*webpackChunkName "notifications"*/ '@/views/notifications'),
    },
    {
        path: '/finance-payout-commission-specification-aggregation',
        name: 'FinancePayoutCommissionSpecificationAggregation',
        component: () =>
            import(/*webpackChunkName "notifications"*/ '@/views/finance_payout_commission_specification_aggregation'),
    },
    {
        path: '/domain-selected-changes/:domain_selected_change',
        name: 'DomainSelectedChanges',
        component: () =>
            import(
                /*webpackChunkName "domain_selected_change"*/ '@/views/menu/domain_selected_changes/DomainSelectedChanges'
            ),
        props: (route) => ({
            domain_selected_change: route.params.domain_selected_change,
        }),
    },
    {
        path: '/user-management',
        name: 'UserManagement',
        component: () => import(/*webpackChunkName "user_management"*/ '@/views/user_management'),
    },
    {
        path: '/visma-net/',
        name: 'VismaNet',
        component: () => import(/*webpackChunkName "visma_net"*/ '@/views/menu/visma_net'),
    },

    /* Legacy - used to be custom views */
    {
        path: '/decisions/',
        name: 'Decisions',
        redirect: {
            name: 'ItemSearch',
            params: {
                resource: 'decisions',
            },
        },
    },
    {
        path: '/decision/:decision_id',
        name: 'MakeDecision',
        redirect: (to) => ({
            name: 'ItemDisplay',
            params: {
                resource: 'decisions',
                resource_id: to.params.decision_id,
            },
        }),
    },

    /* PageNotFound must be last! */
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/*webpackChunkName "page_not_found"*/ '../views/PageNotFound'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
